.everything-wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    /* padding-right: 15px;
    padding-left: 15px; */
    /* padding-top: 30px; */
    justify-content: center;
}

.everything-details {
    width: 50%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 30px;
}

.everything-img {
    width: 50%;
}



.everything-detail-img img {
    width: 55%;
}

.everything-detail-title {
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-size: 40px;
    font-weight: 600;
    line-height: 1.2em;
    letter-spacing: 0;
    text-align: left;
    padding: 0;
    max-width: 80%;
    margin: 0 auto 20px;
}

.everyting-detail-points-wrapper {
    display: flex;
    gap: 15px;
}

.everyting-detail-points-done {
    width: 22px;
    height: 18px;
    margin-top: 20px;
}

.everyting-detail-points-ul {
    padding-left: 0px;
    list-style: none;
    flex-direction: column;
    display: flex;
    gap: 22px;
    margin-left: 20px;
}

.kn-section-inner-evrything {
    /* padding-left: 15px;
    padding-right: 15px; */
    padding-top: 0px;
}

.kn-section-inner-evrything .kn-section-title {
    max-width: 780px;
}

.everyting-detail-points-wrapper {}

.everything-video-circle {
    width: 500px;
    height: 500px;
    position: absolute;
    background: #0E78E5;
    z-index: -1;
    border-radius: 100%;
}

.everyting-detail-point {
    display: flex;
    flex-direction: column;
}

.kn-section-container-everything {
    /* background: #0E78E5; */
}

.everyting-detail-points-btn a {
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    font-weight: 600;
    border-radius: 5px;
    background: #4b169d;
    box-shadow: 100px 0 100px -20px #cf0b59 inset;
    color: #fff;
    padding: 18px 25px 16px;
    display: block;
    transition: ease all .5s;
    top: 0;
    position: relative;
    width: 220px;
    text-decoration: none;
    letter-spacing: 0;
    line-height: 1;
    float: left !important;
    fill: #fff;
    z-index: -1;
}


.everyting-detail-title {
    font-family: 'Rubik', sans-serif;
    color: #0A2B72;
    font-weight: 500;
    font-size: 40px;
}

.everyting-detail-point-name {
    font-family: 'Rubik', sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 45px;
    letter-spacing: 0em;
    text-align: left;
    color: #152a49;
    display: flex;
    align-items: self-start;
    gap: 5px;
}

.everyting-detail-point-name img {
    width: 20px;
    height: 20px;
}

.everyting-detail-point-details {
    font-family: 'Roboto', sans-serif;
    font-size: 30px;
    font-weight: 500;
    line-height: 36px;
    padding-top: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #324067;
}

.everyting-detail-points-btn {
    text-align: center;
}

.everything-video-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.everything-video-inner {
    height: 100%;
}

.everything-video {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.everything-video video {
    width: 100%;
    height: auto;
    margin-bottom: -22px;
}


.everything-video video {}

@media (max-width: 992px) {
    .everything-video-container {
        width: 100%;
    }

    .everything-wrapper {
        flex-direction: column;
        padding-right: 0px;
        padding-left: 0px;
    }

    .everything-details {
        gap: 0px;
        width: 100%;
    }


}

@media (min-width: 767px) {
    .mobile_vedio {
        display: none;
    }


}

@media (max-width: 767px) {
    .kn-section-inner-evrything {
        padding-top: 0px;
        padding-bottom: 60px;
    }

    .kn-section-inner-capabilities .kn-section-title {
        width: 300px;
    }

    .kn-section-inner-capabilities .kn-section-header {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }

    .desktop_vedio {
        display: none;
    }

    .everything-video {
        align-items: center;
        /* padding: 20px; */
    }

    .everyting-detail-points-done img {
        width: 100%;
    }

    .everything-video-circle {
        max-width: 500px;
        width: 100%;
        max-height: 375px;
        height: 100%;

    }




    .everyting-detail-title {
        text-align: center;
        font-size: 28px;
        padding: 40px 15px 15px;
    }

    .everyting-detail-point-name {
        text-align: left;
        font-size: 18px;
        line-height: 26px;
        justify-content: center;
        padding-bottom: 10px;
        font-weight: 400;
        display: flex;
        /* flex-direction: column; */
    }

    .everyting-detail-points-wrapper {
        gap: 0px;
    }

    .everyting-detail-point-details {
        text-align: center;
        font-size: 20px;
        line-height: 30px;
        padding-left: 10px;
        padding-right: 20px;
    }

    .everyting-detail-points-ul {
        padding-left: 10px;
        padding-right: 10px;
        gap: 15px;
        margin-left: 0px;
    }

    .everyting-detail-points-done {

        margin-top: 10px;
    }
}

.everyting-detail-points-done img {
    width: 100%;
}