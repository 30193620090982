.platforms-wrapper {
    padding-top: 60px;
    /* background: #05071e; */
}


.platforms-cards-container {
    width: 100%;
    max-width: 1230px;
    margin: auto;
    padding: 0 20px;
    position: relative;
}

.platforms-cards-inner {
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    padding-bottom: 60px;
}

.platforms-video-title{
    margin: -115px auto 0px !important;
    height: 180px;
}

.platforms-video-container{
    position: relative;
}

.platforms-video-title-inner{
    transform: translate(-50%, -150%) !important;
    height: 200px;
    padding: 0;
    position: relative;
    z-index: 4;
    top: 50%;
    left: 50%;
}


.platforms-video-title-inner h1{
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 55px;
    line-height: 69px;
    text-align: center;
    color: #fff;
    margin: 0 auto;
    text-shadow: 0px 9px 40px #000;
}

.platform-inner{
    
    max-width: 1230px;
    width: 100%;
    margin: 150px auto auto;
}

.platform-inner h2{
    margin-top: 20px;
    font-weight: 500;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    max-width: 1000px;
    margin: 0 auto;
    line-height: 35px;
    text-align: center;
    font-size: 24px !important;
    color: rgb(255 255 255 / 80%);
}

.platforms-cards-wrapper{
    display: flex;
    justify-content: center;
    margin-top: 0px;
}

.platforms-cards-container{
    display: flex;
    flex-direction: column;
    padding-bottom: 60px;
}

.platforms-cards-inner{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -20px;
}

.platforms-card-wrapper{
    border: 1px solid #5864e566;
    border-radius: 20px;
    margin-top: 20px !important;
    background: #101127;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 20px 50px 0px !important;
    padding: 0px 0px !important;
}

.right-side {
    height: 100%;
    position: relative;
    width: 50%;
    margin-left: 50px;
    margin-right: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.right-side .right-side-inner-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.platporm-site-logo {
    height: 70px;
    padding-bottom: 10px;
}

.right-side .right-side-inner-text h2 {
    font-size: 32px !important;
    line-height: 40px;
    margin-top: 15px;
    text-align: center;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: 50px;
    color: #fff;
    margin-bottom: 15px;
}


.right-side p {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    text-align: center;
    line-height: 24px;
    color: #ffffff80;
}


.right-side-inner-btn-wrapper {
    width: 100%;
    margin-top: 10px;
}

.right-side-inner-btn-wrapper .a-fill-btn-kps, .right-side-inner-btn-wrapper .center-btn-kps{
    margin: auto;
    height: 50px;
    max-width: 200px;
    padding-left: 5px;
    padding-right: 5px;

    cursor: pointer;
    text-decoration: none;
    background: #5867e4;
    border-radius: 7px;
    width: 100%;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    box-shadow: none;
    outline: none;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}
 .left-side {
    display: flex;
    justify-content: end;
    width: 50%;
}

.left-side img {
    width: 90%;
    border-radius: 22px;
    vertical-align: middle;
}

.solution-card-kps .left-side {
    width: 50%;
    justify-content: start;
}

.solution-card-kps .left-side img {
    width: 90%;
}

.solution-card-kps .right-side {
    height: 100%;
    position: relative;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0px !important;
    flex-direction: column;
    margin-right: 0px;
}

.platform-card-kps-new .right-side .right-side-inner-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-right: 50px;
    padding-left: 50px;
}

.platporm-site-logo-knightingale {
    height: 65px !important;
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.platform-card-kps-new .right-side .right-side-inner-text h2 {
    font-size: 32px !important;
    line-height: 40px;
    margin-top: 15px;
    text-align: center;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 500;
    color: #fff;
    margin-bottom: 15px;
}

.right-side-inner-btn-wrapper {
    margin-top: 10px;
    width: 100%;
}
.solution-card-kps .right-side p {
    font-size: 18px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    margin-bottom: 0px;
    color: #ffffff80;
}

.solution-card-kps-new {
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: none !important;
    background: #06071b !important;
    padding: 0px 0px !important;
}