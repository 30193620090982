.Footer-wrapper{
    width: 100%;
}
.Footer-container{
    display: flex;
    flex-direction: row;
    width: 100%;
justify-content: space-between;
}
.Footer-container-logo{
    display: flex;
    justify-content: row;
    gap: 20px;
}

.kn-section-container-Footer{
    background: #fff;
}

.Footer-container-img-details{
    color: #fff;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Footer-container-site{
    color: #BDBDBD;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Footer-container-logo-img{
    max-width: 205px;
}

.Footer-container-logo-img img{
    width: 100%;
}


.kn-section-inner-Footer
{
  
justify-content: center;
height: 100px;
}

@media (max-width: 768px) {
    .kn-section-inner-Footer
{
  padding-bottom:100px;
height: 100px;
}

.Footer-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}
}