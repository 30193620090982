   .logos {
        width: 100%;
        overflow: hidden;
    }

    .logos-slide {
        display: flex;
        animation: slide 30s linear infinite;
    }

    .logos-slide img {
        width: 250px;
        height: auto;
        margin: 0 10px;
        border-radius: 15px;
    }

    @keyframes slide {
        0% {
            transform: translateX(0);
        }

        100% {
            transform: translateX(-300%);
        }
    }
    @media (max-width: 768px) {
    .logos-slide {
        display: flex;
        animation: slide 10s linear infinite;
    }}