@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');

.navbar-wrapper {
    position: fixed;
    width: 100%;
    z-index: 1;
}

.navbar-container {
    display: flex;
    justify-content: center;
    width: 100%;
    box-shadow: 0 1px 1px 0 #00000005, 0 2px 11px 0 #00000005, 0 1px 8px 0 #0000000d;
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
}

.navbar-container {
    background-color: #ffffff;
    /* Default color */
    transition: background-color 0.3s ease;
}

.navbar-container.scrolled {
    background-color: #ffffffd9;
    /* Color after scrolling 60px */
}

.navbar-inner {
    max-width: 1230px;
    width: 100%;
    display: flex;
    justify-content: center;
    ;
    height: 60px;
    align-items: center;

}

.navbar-logo-wrapper {
    display: flex;
    justify-content: center;
    height: 60px;
    /* padding-right: 20px; */
}

.navbar-logo-container {
    display: flex;
    align-items: center;
}

.navbar-logo img {
    max-width: 200px;
    margin-bottom: -6px;
}

.navbar-items-wrapper {
    height: 100%;
    display: flex;
    align-items: center;
}

.navbar-items-container {}

.navbar-items {
    display: flex;
    gap: 80px;
}

.navbar-item {
    color: #000;
    text-align: center;
    font-family: "Rubik", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.navbar-item:hover {
    color: #0e78e5;
}

/* .navbar-mobile-items-container{
    position: relative;
    width: 100%;
    height: 100%;
}

.navbar-mobile-items{
    position: absolute;
    bottom: -60px; 
    width: 100%;
}  */

.navbar-mobile-inner-container {
    position: relative;
    width: 100%;
}

.navbar-mobile-inner {
    display: flex;
    flex-direction: column;
    width: 100%;

}

.navbar-dropdown img {
    width: 25px;
}

.navbar-dropdown-container {
    display: flex;
    align-items: center;
}

.navbar-mobile-items-container {
    width: 100%;
}

.navbar-mobile-items {

    width: 100%;
}

.navbar-mobile-item {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 20px;
    padding-right: 20px;

}




/* ------------------------------- */
.main-banner-wrapper {
    /* padding-top: 50px; */
}

.main-banner-inner {
    max-width: 1230px;
    width: 100%;
    display: flex;
}

.banner-container {
    width: 100%;
}

.banner-inner {
    padding-top: 90px;
}







@media (max-width: 769px) {

    .navbar-logo-wrapper {
        /* padding-left: 20px; */
        text-align: center;
        justify-content: center;
        padding: 0px 20px;

    }
}

.main-banner-container {
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;
    overflow: hidden;
}

.banner-header {
    color: #0E78E5;
    font-family: "Rubik", sans-serif;
    font-size: 35px;
    font-style: normal;
    font-weight: 500;
    line-height: 60px;
    /* 113.433% */
}

.banner-header-2 {
    color: #ffffff;
    font-family: "Rubik", sans-serif;
    font-size: 50px;
    font-style: normal;
    font-weight: 500;
    line-height: 70px;
}

.banner-title {
    color: #ffffff;
    font-family: "Rubik", sans-serif;
    font-size: 50px;
    font-style: normal;
    font-weight: 500;
    line-height: 50px;
    /* 109.412% */
}

.banner-text {
    color: #ffffff;
    font-family: "Roboto", sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

}

.banner-btn {
    width: 230px;
    border-radius: 10px;
    border: none;
    text-align: center;
    font-family: "Rubik", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.banner-text1 {
    padding-top: 30px;
    padding-right: 50px;
    display: flex;
}

.banner-btn-1 {
    background: transparent;
    color: #FFF;
    padding-left: 0;
    cursor: pointer;
}

.banner-inner img {
    transition: transform 0.4s ease;
}

.banner-inner img:hover {
    transform: scale(1.05);
}

.banner-btn-2 {
    background: #F3F8FF;
    color: #0A2B72;
}

.banner-btn-1:hover {
    /* background: var(--Gradient, #2490ff); */

}

.banner-btn-2:hover {
    background: #dee5ef;
}


.banner-inner-btn {
    padding-top: 25px;
    padding-bottom: 10px;
    display: flex;
    max-width: 485px;
    justify-content: left;
}

.banner-text2 {
    color: #ffffff;
    font-family: "Roboto", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    padding-top: 20px;
    padding-right: 50px;
    padding-bottom: 50px;
    max-width: 484px;
}


.banner-inner img {
    width: 100%;
}

.banner-container-right {
    padding-top: 35px;
    padding-bottom: 30px;

}

.navbar-item a {
    color: #0A2B72;
}

.activelink a {
    color: #0E78E5;
}

.banner-container-left-side {
    width: 55%;
    display: flex;
    align-items: center;
}

.banner-container-right-side {
    width: 45%;
    display: flex;
    align-items: flex-end;
}

.banner-container-left {
    padding-left: 20px;
    padding-right: 20px;

}

.banner-wrapper-btn {
    width: 100%;
}

.banner-btn-nav-1 {
    width: auto;
    height: auto;
    padding: 10px 12px;
    font-size: 16px;

}


.video-container {
    position: absolute;
    top: 10px;
    left: 0;
    width: 100vw;
    height: 100%;
    overflow: hidden;
    z-index: -1;
}

.video-container video {
    position: absolute;
    top: 10px;
    /* left: 50%; */
    /* transform: translate(-50%, -50%); */
    /* min-width: 100%; */
    /* min-height: 100%; */
    min-height: 559px;
    width: 100vw;
    height: auto;
    object-fit: cover;
    z-index: -1;
}

.video-container-wrapper {
    position: relative;
}

.banner-inner-img {
    /* display: none; */
    margin-bottom: -30px;
}

.main-banner-container {
    background-size: cover;
    /* Ensures the image covers the entire container */
    background-position: center;
    /* Centers the image in the container */
    background-repeat: no-repeat;
    /* Prevents the image from repeating */
    width: 100%;
}

@media screen and (min-width: 1200px) {
    .main-banner-container {
        height: calc(605px + ((100vw - 1400px)*.4));
    }

}


@media screen and (min-width: 992px) {

    .mobile-video {
        display: none;
    }

}

@media screen and (min-width: 768px) and (max-width: 992px) {
    .main-banner-inner {
        flex-direction: column;
    }

    .banner-header {
        font-size: 55px;
    }

    .banner-title {
        font-size: 65px;
    }

    .banner-text {
        font-size: 25px;
    }

    .banner-inner-img {
        display: block;
        width: 80%;
    }

    .banner-container-right {
        display: flex;
        justify-content: center;
    }
}

@media (max-width: 992px) {

    .banner-wrapper-btn {
        display: flex;
        justify-content: center;
        /* height: 42px; */
    }

    .navbar-logo img {
        max-width: 200px;
    }

    /* .banner-container-left-side {
        width: 100%;
        padding-top: 50px;
    } */

    .banner-container-right-side {
        width: 100%;
        justify-content: center;
        height: 448px;
    }

    .main-banner-inner {
        flex-direction: column;
    }

    .banner-header {
        font-size: 25px;
        line-height: 20px;

    }

    .banner-title {
        font-size: 40px;
        text-align: center;
        line-height: 40px;

    }

    .banner-text {
        font-size: 22px;
        padding-top: 0px;
        text-align: center;
        padding-top: 20px;
    }



    .banner-inner-btn {
        align-items: center;
        flex-direction: column;
        gap: 20px;
        justify-content: center;
        padding-top: 20px;
        /* position: absolute; */
        width: 100%;
    }

    .banner-text1 {
        padding-right: 0px;
    }

    .banner-header-2 {
        text-align: center;
        font-size: 38px;
        line-height: 50px;
        padding-top: 10px;
        width: 300px;
    }


    .banner-container-right {
        padding-bottom: 0px;
        padding-top: 10px;
    }

    /* .banner-container-left {
        padding-top: 20px;
        padding-left: 20px;
        padding-right: 20px;
    } */

    .banner-btn {
        font-size: 18px;
        height: 55px;
        max-width: 220px;
        width: 100%
    }

    .banner-text {

        padding-right: 15px;
        padding-left: 15px;
    }
}


@media (max-width: 992px) {
    .main-banner-container {
        height: 1100px;
    }

    .mobile-video {
        display: block;
        max-height: 1400px;
        min-height: 100% !important;
    }

    .desktop-video {
        display: none;
    }

    .kn-section-inner-BannerBlue {
        margin: 20px;
        padding-top: 0px;
    }

    .banner-text2 {
        padding-right: 20px;
        padding-left: 20px;
        text-align: center;
        line-height: 22px;
        font-size: 16px;

    }

    .banner-inner-img video {
        object-fit: cover;
        position: absolute;
        height: 100%;
        bottom: 0px;
        z-index: -1;
    }

    .banner-inner {

        padding-top: 0px;
        display: flex;
        flex-direction: column;
        align-items: center;

    }

    .banner-inner-img {
        display: block;
        position: relative;
        /* height: 501px; */
        z-index: 0;
        /* width: 100vw; */
    }

    .video-container {}

    .banner-container-left-side {
        width: 100%;
        padding-top: 70px;
        height: 632px;
    }

    .banner-container-left {
        padding-top: 20px;
        padding-left: 20px;
        padding-right: 20px;
    }

}

@media (max-width: 767px) {}

/* video{
   
} */