/* Trailer Video Container */
.trailer-container {
  padding-top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  flex-direction: column;
}

.trailer-wrapper {
  position: relative;
  max-width: 1000px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 70px #176284;
  /* Green glow color */
  transition: box-shadow 0.3s ease-in-out;
  border-radius: 20px;

}

.trailer-glow {
  padding-top: 50px;
  padding-left: 15px;
  padding-right: 15px;
}

/* Trailer Video Styling */
.trailer-trailervideo {
  width: 100%;
  max-width: 800;
  height: auto;
  border-radius: 20px;
  /* Rounded border */
  display: block;
}

/* Play Button Styling */
.trailer-videobtn {
  position: absolute;
  top: 52%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: none;
  border: none;
  cursor: pointer;
}

.trailer-videobtn img:hover {
  transform: scale(1.2);
  transition: 0.4s ease;
}

.trailer-videobtn img {
  width: 150px;
  height: 150px;
  cursor: pointer;
}

/* Custom Modal */
.trailer-custommodal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.trailer-modalcontent {
  position: relative;
  background-color: #ffffff00;
  padding: 20px;
  width: 80%;
  max-width: 800px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

#vp-preview{
  border: 1px solid #ffffff;
}

.trailer-closemodalbtn {
  position: absolute;
  top: 0px;
  right: 10px;
  background: none;
  border: none;
  font-size: 36px;
  cursor: pointer;
  color: #fff;
  /* line-height: 0; */
}

.trailer-closemodalbtn:hover {
  color: #ffffffe1;
}

/* Vimeo Wrapper */
.trailer-vimeo-wrapper {
  width: 100%;
  height: 500px;
}



@media (max-width: 768px) {

  .trailer-closemodalbtn {
    top: -53px;
  }
  .trailer-container {
    /* padding-top: 40px; */
}

.trailer-glow {
  padding-top: 25px;
}

  .trailer-videobtn img {
    height: 100px;
    width: 100px;
  }

  .trailer-modalcontent iframe {
    height: 250px;
  }

  .trailer-modalcontent {
    padding: 0px;
    width: 100%;
    background-color: transparent;
  }

  .trailer-videobtn {
    top: 52%;
  }

}