.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
.kn-section-inner{
    max-width: 1230px;
    width: 100%;
    padding-top: 30px;
    display: flex;
    padding-bottom: 30px;
    flex-direction: column;
    align-items: center;
}

.kn-section-container{
    display: flex;
    justify-content: center;
    width: 100%;
}

.kn-section-title{
    color: #0A2B72;
text-align: center;
font-family: "Rubik", sans-serif;
font-size: 40px;
font-style: normal;
font-weight: 500;
line-height: normal;
margin: 0 0 10px;
}


a {
  text-decoration: none;
  color: #337ab7;
}