.leadership-wrapper {
    padding-top: 60px;
    background: #f5f8ff;
    overflow-y: auto;
    height: 100%;
    width: 100%;
}

.leadership-container {
    padding-bottom: 50px;
}

.leadership_position {
    color: #324067;
    font-family: "roboto", sans-serif;
    padding-bottom: 20px;
    margin-left: 10px;
    margin-right: 10px;
    font-weight: 600;
}

.leadership-card-details-text {
    text-align: left;
    color: #324067 !important;
    font-family: "roboto", sans-serif;
    padding-top: 5px;
    margin-left: 10px;
    margin-right: 10px;
    font-weight: 500;
    line-height: 25px;
    font-size: 18px;
}


.leadership-card-details-text::marker {
    font-size: 25px;
    color: #0A2B72;
}

.leadership-cards-container {
    width: 100%;
    max-width: 1230px;
    margin: auto;
    padding: 0 20px;
    position: relative;
}

.leadership-card-wrapper-2item-wrapper {
    width: 100%;
    max-width: 1230px;
    margin: auto;
    padding: 0 20px;
    position: relative;
}

.leadership-cards-inner {
    width: 100%;
    max-width: 980px;
    margin: auto;
    display: flex;
    flex-direction: column;
    padding-bottom: 40px;
}

.leadership-cards-title-wrapper {}

.leadership-cards-title-container {
    padding: 30px 0px 50px;
    transform: translate(-50%, 0);
    position: relative;
    /* z-index: 4; */
    top: 50%;
    left: 50%;
}

.leadership-cards-title {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 50px;
    line-height: 69px;
    text-align: center;
    color: #0A2B72;
    margin: 0 auto;
}

.leadership-card-wrapper-2item {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 115px;
    row-gap: 20px;
}

.leadership-card-wrapper {
    /* background: #101127;
    border-radius: 8px;
    text-align: center;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 25px; */
}

.leadership-card-container {
    /* background: #101127;
    border-radius: 8px;
    text-align: center;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 25px; */
}

.leadership-card-inner {
    background: #fff;
    border-radius: 8px;
    text-align: center;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 25px;
    align-items: center;
    box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.1);
}

.leadership-card-img {
    position: relative;
    padding-top: 45px;
}

.leadership-all-details {
    height: 100%;
}

.leadership-card-img img {
    border-radius: 10px;
    width: 100%;
    height: auto;
    max-width: 280px;
    min-height: 280px;
    object-fit: cover;
    max-height: 280px;
    vertical-align: middle;
}

.leadership-card-img-product {
    max-width: 240px !important;
    min-height: 240px !important;
}

.leadership-card-name {
    text-align: center;
}

.leadership-card-name-inner {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 39px;
    text-align: center;
    margin-top: 25px;
    margin-bottom: 0;
    color: #0A2B72;
    max-width: 390px;
    margin-left: auto;
    margin-right: auto;
}

.leadership-linkdin a img {
    width: 20px;
}

.leadership-card-details-product {
    padding-bottom: 40px !important;
}

.leadership-linkdin {
    display: flex;
    justify-content: start;
    width: 100%;
    padding: 48px;
    padding-bottom: 10px;
    padding-top: 5px;
    font-size: 20px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    color: #5569a2;
    line-height: 25px;
}

.leadership-linkdin a {
    display: flex;
    align-items: center;
    gap: 10px;
}

.leadership-linkdin a span {
    display: inline-block;
    width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #2175f3;
}

.leadership-card-position-inner {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #5569a2;
    max-width: 390px;
    line-height: 20px;
    padding-bottom: 5px;
    margin-left: auto;
    margin-right: auto;
}

.leadership-card-details {
    padding: 22px 22px 22px 22px;
    padding-bottom: 10px;
    padding-top: 5px;
    width: 100%;
    text-align: center;
}

.leadership-card-details-inner {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    list-style: disc;
}

.leadership-card-details-1 {
    color: #324067;
    font-family: "roboto", sans-serif;
    padding-top: 5px;
    margin-left: 10px;
    margin-right: 10px;
    font-weight: 500;
    font-size: 18px;
}

.leadership-card-details-2 {
    color: #bdbdbd;
    font-family: "roboto", sans-serif;
    padding-top: 5px;
    margin-left: 10px;
    margin-right: 10px;
    font-weight: 500;
    font-size: 18px;
}

.leadership-card-details-3 {
    color: #bdbdbd;
    font-family: "roboto", sans-serif;
    padding-top: 5px;
    margin-left: 10px;
    margin-right: 10px;
    font-weight: 500;
    font-size: 18px;
}

.leadership-card-button-wrapper {
    position: relative;
    height: 85px;
    display: flex;
    justify-content: center;
}

.leadership-card-button-inner {
    display: flex;
    margin-top: 10px;
    gap: 10px;
    margin: 0px 20px 30px 30px;
    justify-content: center;
}

.leadership-card-button-container {}

.leadership-card-button-container a {
    margin: auto;
    height: 50px;
    max-width: 200px;
    padding-left: 20px !important;
    padding-right: 20px !important;
    display: flex;
    gap: 8px;

    cursor: pointer;
    text-decoration: none;
    background: #5867e4;
    border-radius: 7px;
    width: 100%;
    height: 58px;
    max-width: 235px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    color: #fff;
    align-items: center;
    justify-content: center;
    border: none;
    box-shadow: none;
    outline: none;
}

.leadership-card-button-container a img {
    width: 30px;
    padding-bottom: 2px;
    max-width: 100%;
    height: auto;
}

.leadership-card-button-container a span {}


@media (max-width: 995px) and (min-width: 768px) {
    .leadership-card-wrapper-2item {
        grid-template-columns: repeat(1, 1fr);
    }
}

@media (max-width: 767px) {
    .leadership-card-wrapper-2item {
        display: block;
        margin-top: 0px !important;
    }

    .leadership-cards-title {
        font-size: 30px;
        line-height: 40px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .leadership-card-img img {
        max-width: 250px;
        min-height: 250px;
    }

    .leadership-linkdin a span {
        font-size: 16px;
    }

    .leadership-card-details {
        padding: 5px 5px 22px 5px;
    }

    .leadership-cards-title-container {
        padding: 10px 0px 30px;
    }

    .leadership-card-img-product {
        max-width: 220px !important;
        min-height: 220px !important;
    }

    .leadership-linkdin {
        font-size: 20px;
    }
}