.kn-section-title {
    font-family: 'Poppins', sans-serif;
    font-size: 50px;
    font-weight: 600;
    line-height: 59px;
    letter-spacing: 0em;
    text-align: center;
    color: #0A2B72;
    margin-bottom: 0px;
}

.kn-section-description {
    font-family: 'Inter', sans-serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 30px;
    color: #152a49;
    max-width: 575px;
}

.kn-section-header {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.capabilities-wrapper {
    display: flex;
    padding-top: 50px;
}

.capabilities-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.capabilities-wrapper-row {
    display: flex;
    gap: 20px;
}

.capabilities-wrapper-row-1 {}

.capabilities-wrapper-row-2 {}

.capabilities-btn-wrapper {
    display: grid;
    row-gap: 32px;
    column-gap: 42px;
    grid-template-columns: repeat(4, 1fr);
}

.capabilities-btn-container {
    min-height: 130px;
    align-items: center;
    background: #00a3e314;
    border: 1px solid #0000;
    border-radius: 18px;
    display: flex;
    flex: 1 1;
    flex-direction: column;
    gap: 12px;
    justify-content: center;
    padding: 15px 18px;
}

.capabilities-btn-inner {
    max-width: 164px;
    min-width: 132px;
}

.capabilities-btn-img {}

.capabilities-btn-img img {
    width: 100%;
    max-width: 55px;
}

.capabilities-btn-name {
    text-align: center;
}

.capabilities-btn-name p {
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    color: #152a49;
    margin:0px;
}


.kn-section-container-capabilities {
    /* background: #F3F8FF; */
    padding-top: 120px;
    padding-bottom: 120px;
    /* background: url('../../img/Capabilities/space_background.jpg') center/cover no-repeat; */
}


/* CSS talk bubble */
.talk-bubble {
	margin: 40px;
  display: inline-block;
  position: relative;
	width: 200px;
	height: auto;
	background-color: lightyellow;
}
.border{
  border: 8px solid #666;
}
.round{
  border-radius: 30px;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;

}
.tri-right.border.btm-left-in:before {
	content: ' ';
	position: absolute;
	width: 0;
	height: 0;
	left: 30px;
  right: auto;
  top: auto;
	bottom: -40px;
	border: 20px solid;
	border-color: #666 transparent transparent #666;
}
.tri-right.btm-left-in:after{
	content: ' ';
	position: absolute;
	width: 0;
	height: 0;
	left: 38px;
  right: auto;
  top: auto;
	bottom: -20px;
	border: 12px solid;
	border-color: lightyellow transparent transparent lightyellow;
}


@media (max-width: 768px) {
    .kn-section-container-capabilities {
        padding-top: 75px;
    }
    .kn-section-description {
        padding-left: 0px!important;
        padding-right: 0px!important;
    }

    .capabilities-container {
        width: 100%;
    }

    .capabilities-container {
        width: 100%;
    }

    .capabilities-wrapper {
        width: 100%;
    }

    .kn-section-inner-capabilities {
        padding-left: 20px;
        padding-right: 20px;
    }

    .capabilities-btn-wrapper {
        grid-template-columns: repeat(2, 1fr);
        width: 100%;
        /* row-gap: 10px; */
        grid-gap: 20px;
        column-gap: 10px;
    }

    .kn-section-description {
        font-size: 20px;
        line-height: 30px;
        padding-left: 20px;
        padding-right: 20px;
        max-width: 350px;
    }

    .kn-section-title {
        font-size: 30px;
        line-height: 40px;
        /* padding-left: 40px;
        padding-right: 40px; */
    }

    .capabilities-btn-container {

        padding: 12px 0px;
        gap: 5px;
    }

    .capabilities-btn-inner {
        max-width: 160px;
        min-width: 80px;
        display: flex;
        margin: auto;
        width: 100%;
    }

    .capabilities-btn-img img {

        max-width: 45px;
    }


    .capabilities-btn-name div {
        font-size: 14px;
    }
}

@media (min-width: 768px) and (max-width: 992px) {
    .capabilities-btn-wrapper {
        grid-template-columns: repeat(4, 1fr);
    }
}