.page-limitation-wrapper{
    width: 100%;
    position: relative;
    padding-top: 50px;
}

.page-limitation {
    max-width: 1230px;
    margin: auto;
    padding: 0 20px;
    background-color: #fafafa;
}

 .page-limitation .top-conatiner {
    padding: 50px 0px;
}

.page-limitation .top-conatiner h1 {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 55px;
    line-height: 69px;
    text-align: center;
    color: #0A2B72;
    margin: 0;
}

.input-comman-container input{
border: none;
outline: none;
padding-left: 10px;
    width: 100%;
}

.select-label{
    display: flex;
    width: 100%;
    justify-content: left;
    gap: 10px;
    padding-left: 10px;
}

.input-comman-container label input{
border: none;
outline: none;
padding-left: 10px;
    width: 100%;
}

.select-input{
    width: unset!important;
}
.page-limitation .mid-container {
    display: flex;
    flex-direction: column;
    padding-bottom: 60px;
}

.page-limitation .mid-container .form-card-container {
    background: #fff;
    border-radius: 22px;
    width: 100%;
    max-width: 700px;
    margin: auto;
        background: #fff;
    border-radius: 5px;
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    border-color: #e3e3e3;
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}

.page-limitation .mid-container .form-card-container .form-inner-container {
    padding: 30px 40px 40px;
}


button, input, optgroup, select, textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

.form-conatiner {
    margin-bottom: 45px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.form-conatiner .input-conatiner {
    width: 100%;
    display: block;
    background-color: #fafafa;
    border-color: #cecece;
    border-width: 1px;
    border-radius: 5px;
    width: 100%;
    margin-bottom: 0px;
    font-size: 15px;
    color: #000;
    width: 100%;
    font-weight: 400;
    padding-left: 10px;
    line-height: 1.8;
    border: 1px solid #bbb;
    min-height: 3em;
    max-width: 100%;
}

.form-conatiner select {
  outline: none;
  border: none;
  width: 98%;
  padding-left: 10px;
  padding-right: 10px;
}
select {
    word-wrap: normal;
}
button, select {
    text-transform: none;
}

.video-banner-desktop{
    position: relative;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: translate(-50%, 0%);
    z-index: 0;
    min-height: 550px;
}

.video-banner-mobile{
    position: relative;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: translate(-50%, 0%);
    z-index: 0;
    min-height: 550px;
}

.form-conatiner label {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 500;
    color: #575e71;
}

.col-form-label {
    padding-top: calc(0.375rem + 1px);
    padding-bottom: calc(0.375rem + 1px);
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.5;
}

.form-label{
    color: #424242!important;
    margin-bottom: 15px;
    font-size: 16px!important;
    font-family: 'Roboto', sans-serif;
    line-height: 22px;
}


 .page-limitation .mid-container .form-card-container .form-inner-container .form-btn-group {
    width: 100%;
    text-align: center;
    margin-bottom: 0;
}

.form-conatiner .form-submit {
    background: #00a1e1;
    border-radius: 7px;
    color: #fff;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    width: 100%;
    text-shadow: none;
    height: 52px;
    border: 0;
    box-shadow: none;
    text-decoration: none;
    outline: none;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    line-height: 1;
    /* cursor: not-allowed !important; */
}

.contact-details-wrapper {
    margin-top: 30px;
}

.contact-details-container {
    width: 100%;
    display: flex;
    justify-content: center;
}

.contact-details-inner {
    max-width: 900px;
    width: 100%;
}

.contact-details-items {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 10px !important;
}

.contact-details-item {
    height: 100%;
    width: 100%;
}
.contact-details-item {
    background: #101127;
    color: #fff;
    border-radius: 8px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 30px;
}

.contact-details-item-inner {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.contact-details-item-mob-icon img {
    width: 40px;
    padding-bottom: 13px;
}

.contact-details-item-mob-text {
    /* padding-bottom: 8px; */
    font-weight: 700;
    font-family: "roboto", sans-serif;
}

.contact-details-item-mob-number a {
    text-decoration: none;
}

.contact-details-item.address-item {
    grid-column: span 2;
    grid-row: span 2;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 60px;
    justify-content: flex-start;
}
.contact-details-item {
    height: 100%;
    width: 100%;
}
.contact-details-item {
    background: #101127;
    color: #fff;
    border-radius: 8px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 30px;
}

.contact-details-item.address-item .contact-details-item-inner {
    flex-direction: column;
    min-width: 225px;
}


.contact-details-item.address-item .contact-details-item-inner .contact-details-address-content {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.contact-details-item-add-text {
    text-align: left;
    padding-bottom: 10px;
    line-height: 20px;
}

.contact-details-item.address-item .contact-details-item-inner .contact-details-item-add-icon img {
    width: 25px;
    border-radius: 2px;
    padding-bottom: 5px;
}

.contact-details-item.address-item .contact-details-item-inner:last-child .contact-details-address-content {
    margin-top: -17px;
}
.contact-details-item.address-item .contact-details-item-inner .contact-details-address-content {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.contact-details-item {
    height: 100%;
    width: 100%;
}
.contact-details-item {
    background: #101127;
    color: #fff;
    border-radius: 8px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 30px;
}

.contact-details-item-add-icon img {
    width: 40px;
    padding-bottom: 13px;
}

.contact-details-item-add-text a {
    text-decoration: none;
}

.form-conatiner .error-message{
    color: red;
}



.full-width-conatiner-kps.form-send-container-kps .limitted-width-conatiner-kps .page-inner-container.form-data-enter-container-kps .form-conatienr .form-inner-conatienr .kps-form-conatiner .form-submit.disable-btn-kps {
    cursor: not-allowed;
}
.full-width-conatiner-kps.form-send-container-kps .limitted-width-conatiner-kps .page-inner-container.form-data-enter-container-kps .form-conatienr .form-inner-conatienr .kps-form-conatiner .form-submit {
    background-color: #ffae37;
    color: #000;
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    font-weight: 500;
    border-radius: 5px;
    padding: 20px;
    width: 100%;
    text-shadow: none;
    min-height: 47px;
    border: 0;
    box-shadow: none;
    line-height: 1;
    cursor: pointer;
}
[type=button], [type=reset], [type=submit], button {
    -webkit-appearance: button;
}

.input-comman-container{
    display: flex;
    background-color: #fafafa;
    border-color: #cecece;
    border-width: 1px;
    border-radius: 5px;
    width: 100%;
    margin-bottom: 0px;
    font-size: 15px;
    color: #000;
    width: 100%;
    font-weight: 400;
    line-height: 1.8;
    border: 1px solid #bbb;
    min-height: 3em;
    max-width: 100%;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
}

.cmn-wrp-lbl{
    display: flex;
    align-items: center;
    gap: 10px;
}








.video-banner-desktop{
    display: none;
}
@media (max-width: 768px){
    .privacy-note{
        margin-bottom: 15px;
    }

.page-limitation .mid-container .form-card-container .form-inner-container {
    padding: 30px 15px 40px;
}}

@media (min-width: 768px){
    .video-banner-desktop{
        display: block;
    }
    .video-banner-mobile{
        display: none;
    }
  
.col-md-3 {
    flex: 0 0 auto;
    width: 25%;
}
label {
    display: inline-block;
}
.col-md-6 {
    flex: 0 0 auto;
    width: 50%;
}
}

