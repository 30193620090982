.Frequently-title {
    font-family: 'Rubik', sans-serif;
    font-size: 35px;
    font-weight: 500;
    line-height: 59px;
    letter-spacing: 0em;
    text-align: center;
    color: #0A2B72;
    margin-bottom: 0px
}

.Frequently-wrapper {
    width: 100%;
    display: flex;
    padding-top: 20px;
    flex-direction: column;
    gap: 30px;
}

.Frequently-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 30px;
}

.Frequently-item {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 24px;
    /* height: 100%; */
    border-radius: 20px;
    background: #0a2b7217;
}

.Frequently-item-name {
    color: #152a49;
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
}

.Frequently-item-details {
    color: #324067;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}
.Frequently-item-wrappper{
    width: 100%;
    display: flex;
}

.kn-section-inner-Frequently {
    padding: 100px 20px 35px 20px;
}

@media (max-width: 992px) {
    .Frequently-container {
        flex-direction: column;
        align-items: center;
    }

    .Frequently-item {
        text-align: center;
    }

    .Frequently-title {
        font-size: 30px;
        line-height: 40px;
        padding-left: 50px;
        padding-right: 50px;
    }

    .Frequently-wrapper {
        padding-top: 0px;
        justify-content: center;
        display: flex;
        align-items: center;
    }

    .Frequently-item-wrappper{
max-width: 350px;
    }
}