.kn-section-container-BannerBlue {
    width: 100%;
    position: relative;
    /* padding-left: 20px;
    padding-right: 20px; */
}

.bannerblue-container {}

.bannerblue-inner {
    width: 100%;
    display: flex;
}

.bannerblue-img {
    width:40%;
    display: flex;
    justify-content: center;
}

.bannerblue-img img {
    position: absolute;
    bottom: 0px;
    max-width: 350px;
    /* bottom: 20px; */
}

.bannerblue-details {
    width: 60%;
    display: flex;
    align-items: center;
}

.bannerblue-details-inner {}

.bannerblue-details-inner-title {
    font-family: 'Inter', sans-serif;
    font-size: 35px;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: left;
    color: #ffffff;
}
.kn-section-inner-BannerBlue{
min-height: 260px;
justify-content: center;
background: #06173E;
border-radius: 20px;
}

.bannerblue-details-inner-description {
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    color: #ffffffb3;
    padding-top: 20px;
    max-width: 600px;
}

.bannerblue-details-inner-btn {
    display: flex;
    justify-content: left;
    padding-top: 25px;

}

.bannerblue-details-inner-btn-wrapper:hover {
    background: #dee5ef;
}
.bannerblue-details-inner-btn-wrapper {
    cursor: pointer;
    color: #0A2B72;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-weight: 600;
    /* height: 55px; */
    line-height: 35px;
    text-align: center;
    background: #ffffff;
    border-radius: 10px;
    padding: 10px;
    max-width: 172px;
    width: 100%;

}

.kn-section-wrpper-BannerBlue{
    padding-top: 70px;
    margin-bottom: 0px;
}

#chat-popup {

}

.bannerblue-wrapper{
    width: 100%;
}


  
@media (max-width: 767px){
    .bannerblue-img img {
       
        bottom: 20px;
       
    }
    .bannerblue-img {
        width: 100%;
        height: 260px;
    }
    .bannerblue-inner {
        flex-direction: column-reverse;
        padding:20px;
        width: auto;
        }

        .bannerblue-details-inner-btn {
            justify-content: center;
        }

        .bannerblue-details-inner-description {
            font-family: 'Roboto', sans-serif;
            line-height: 24px;
            text-align: center;
        }

        .bannerblue-img img {
            /* position: relative
            ; */
            width: 100%;
            max-width: 240px;
        }
        .bannerblue-details{
            margin-top: 20px;
            width: 100%;
        }
        .bannerblue-details-inner-title {
            font-size: 26px;
    line-height: 32px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
        }
}

