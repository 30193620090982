.ProdutEco-container-row {
    display: flex;
    gap: 30px;
}

.ProdutEco-container-row-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    max-height: 360px;
}

.ProdutEco-container-row-item-wrapper {
    max-width: 350px;
    width: 100%;
}

.ProdutEco-container-request{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 15px;
    padding-bottom: 30px;
}
.ProdutEco-container-row-item-1 {

    background: #e9f3ff;
    border: 2px solid #8eeaf5;
    border-radius: 20px;
}

.ProdutEco-container-request-detail{
    color: #4d4c4c;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 400;
    max-width: 1000px;
    text-align: center;
    padding-bottom: 30px;
}

.ProdutEco-container{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ProdutEco-inner{
    display: flex;
    gap: 30px;
}

.ProdutEco-container-request-btn-inner{
    color: #fff;
    font-family: 'Rubik', sans-serif;
    font-size: 18px;
    font-weight: 500;
    max-height: 65px;
    line-height: 42px;
    text-align: center;
    padding: 10px;
    cursor: pointer;

}

.ProdutEco-container-request-btn:hover {
    background: var(--Gradient, #2490ff);
}
.ProdutEco-container-request-btn{
    background: var(--Gradient, #0E78E5);
    color: #FFF;
    width: 220px;
    border-radius: 10px;
    height: 60px;
    flex-shrink: 0;
    border: none;
    text-align: center;
    font-family: "Rubik", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
}

.ProdutEco-container-row-item-2 {

    background: #e9f3ff;
    border: 2px solid #8ca4d9;
    border-radius: 20px;

}

.ProdutEco-container-row-item-3 {

    background: #e9f3ff;
    border: 2px solid #8ca4d9;
    border-radius: 20px;

}

.ProdutEco-container-row-item-4{
    background: #e9f3ff;
    border: 2px solid #90d2ee;
    border-radius: 20px;
}

.kn-section-inner-ProdutEco {
    padding-top: 70px;
    padding-bottom: 35px;
}

.ProdutEco-wrapper {
    padding-top: 100px;
    padding-bottom: 35px;
    padding-left: 20px;
    padding-right: 20px;
}

.ProdutEco-container-row-item-img {
    position: relative;
    top: -80px;
}

.ProdutEco-container-row-item-detail-wrpper {
    position: relative;
    top: -50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.ProdutEco-container-row-item-img img {
    max-width: 245px;

}

.ProdutEco-container-request-btn-inner{

}

.ProdutEco-container-row-item-logo {
    margin-bottom: 30px;
}

.ProdutEco-container-row-item-logo img {
    max-width: 180px;
}

.ProdutEco-container-row-item-description {
    color: #0A2B72;
    font-family: 'Rubik', sans-serif;
    font-size: 22px;
    font-weight: 500;
    line-height: 1.2em;
    letter-spacing: 0;
    text-align: center;
}


.ProdutEco-container-row-item-status {
    padding-top: 20px;
    padding-bottom: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.ProdutEco-container-row-item-status-title {
    color: #0A2B72;
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    font-weight: 500;


}

.ProdutEco-container-row-item-stage {
    padding-top: 15px;
    padding-bottom: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.ProdutEco-container-row-item-stage-title {
    color: #0E78E5;
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    font-weight: 500;
}

.ProdutEco-container-row-item-stage-detail {
    color: #0A2B72;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    line-height: 20px;
}

.ProdutEco-container-row-item-status-detail {
    color: #575E71;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 500;
}
@media (max-width: 768px){

    .ProdutEco-container-row-item-img {
        top: -62px;
    }
    .kn-section-description-capabilities{
        padding-left: 20px!important;
        padding-right: 20px!important;
    }
    .ProdutEco-container-row-item {
       
        max-width: 320px;
    }
    .ProdutEco-container-request-detail {
        padding-left: 10px;
        padding-right: 10px;
    }
    .Frequently-container {
        max-width: 350px;

    }

    .Frequently-item {
    }
    .Frequently-item-name ,.Frequently-item-details{
        text-align: left;
    }

    .ProdutEco-inner {
        flex-direction: column;
    }
}

@media (max-width: 992px){
    .ProdutEco-container-row {
    
        flex-direction: column;
    }
   
    .ProdutEco-container-row-item-wrapper {
        max-width: 100%;
        width: 100%;
        padding-bottom: 50px;
    }
    .ProdutEco-container-request-detail {
        max-width: 600px;
    }

    .Frequently-container {
        flex-direction: column;
    }

    .ProdutEco-container-request {
        margin-top: -30px;
    }
}

@media (min-width: 992px)and (max-width: 1300px){
    
    .ProdutEco-container-row {
    
        flex-direction: row;
    }

    .ProdutEco-inner{
        flex-direction: column;
    }
}