.site-main-body-conatiner {
    width: 100%;
    float: left;
    display: block;
}

.site-main-body-conatiner .site-inner-container {
    width: 100%;
    float: left;
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 60px 0px 0px;
}

.site-main-body-conatiner .site-inner-container .main-section-container.white-paper-sestion {
    background-color: #ffff;
}

.site-main-body-conatiner .site-inner-container .main-section-container {
    position: relative;
    width: 100%;
    height: 100%;
    margin: auto;
    background-color: #031227;
}

.main-section-container {
    position: relative;
    width: 100%;
    height: 100%;
    margin: auto;
    z-index: 0;
    display: block;
}

.white-page-bg-image-conatiner {
    width: 100%;
    height: 100%;
    position: relative;
    display: block;
    z-index: 1;
}

.white-page-bg-image-conatiner .white-gb-image {
    width: 100%;
    position: relative;
    height: 100%;
    object-fit: cover;
    display: block;
}

.white-page-bg-image-conatiner .white-mobile-gb-image {
    display: none;
}

.white-paper-main-conatiner {
    width: 100%;
    max-width: 1000px;
    margin: 30px auto;
}

.white-paper-main-conatiner .white-paper-card.top-section {
    margin-top: -190px;
    z-index: 2;
    position: relative;
}

.white-paper-main-conatiner .white-paper-card {
    margin: 20px auto;
}

.white-paper-main-conatiner .white-paper-card .white-paper-card-header {
    width: 100%;
    text-align: center;
    padding: 20px 0px;
}

.white-paper-main-conatiner .white-paper-card .white-paper-card-header h2.main-topic {
    color: #0A2B72;
    font-size: 50px;
    line-height: 58px;
    font-weight: 600;
    margin-bottom: 30px;
}

.white-paper-main-conatiner .white-paper-card .white-paper-card-header h2 {
    font-family: "Poppins", sans-serif;
    font-size: 32px;
    line-height: 38px;
    color: #000;
    font-weight: 600;
    margin-bottom: 22px;
}

.white-paper-main-conatiner .white-paper-card .white-paper-card-body.table-of-content {
    margin-bottom: 100px;
}

.white-paper-main-conatiner .white-paper-card .white-paper-card-body ol {
    font-family: "Roboto", sans-serif;
    width: 80%;
    margin: auto;
    padding: 60px;
    margin-top: 20px;
    border: 1px solid #bbb;
    border-radius: 10px;
}

.white-paper-main-conatiner .white-paper-card .white-paper-card-body ol li {
    margin-bottom: 6px;
}

::marker {
    unicode-bidi: isolate;
    font-variant-numeric: tabular-nums;
    text-transform: none;
    text-indent: 0px !important;
    text-align: start !important;
    text-align-last: start !important;
}

.white-paper-main-conatiner .white-paper-card .white-paper-card-body ol li a {
    font-size: 18px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    line-height: 20px;
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

ol ol, ol ul, ul ol, ul ul {
    margin-bottom: 0;
}

.white-paper-main-conatiner .white-paper-card .white-paper-card-body ol ul li {
    list-style: none;
}


.white-paper-main-conatiner .white-paper-card .white-paper-card-body ol li {
    margin-bottom: 6px;
}

.white-paper-main-conatiner .white-paper-card .white-paper-card-body ol ul li a {
    font-size: 18px;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    line-height: 20px;
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

.white-paper-main-conatiner .white-paper-card .white-paper-card-body ol ul li {
    list-style: none;
}
.white-paper-main-conatiner .white-paper-card .white-paper-card-body ol li {
    margin-bottom: 6px;
}

dl, ol, ul {
    margin-top: 0;
    margin-bottom: 1rem;
}

ol, ul {
    padding-left: 2rem;
}

.white-paper-main-conatiner .white-paper-card {
    margin: 20px auto;
}

.white-paper-main-conatiner .white-paper-card .white-paper-card-header {
    width: 100%;
    text-align: center;
    padding: 20px 0px;
}

.white-paper-main-conatiner .white-paper-card .white-paper-card-header h2 {
    font-family: "Poppins", sans-serif;
    font-size: 32px;
    line-height: 38px;
    color: #000;
    font-weight: 600;
    margin-bottom: 22px;
}

.white-paper-main-conatiner .white-paper-card .white-paper-card-body p {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    text-align: justify;
    line-height: 23px;
    color: #000;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 1rem;
}

.white-paper-main-conatiner .white-paper-card .white-paper-card-body h2 {
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    line-height: 25px;
    color: #000;
    font-weight: 500;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
}




.white-paper-main-conatiner .white-paper-card .white-paper-card-header h3{
    font-size: calc(1.3rem + .6vw);
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
}

.white-paper-card-body ul{
    margin-top: 0;
    list-style:disc ;
    margin-bottom: 1rem;
    padding-left: 2rem;
}

@media (max-width: 767px){
.white-paper-main-conatiner {
    max-width: 85%;
    padding-top: 100px;
}
.white-paper-main-conatiner .white-paper-card .white-paper-card-header h2.main-topic {
    font-family: "Poppins", sans-serif;
    font-size:30px;
    line-height: 30px;
    font-weight: 600;
    margin-bottom: 18px;
}

.white-paper-main-conatiner .white-paper-card .white-paper-card-body ol {
padding: 20px 10px 20px;
width: 100%;
}
}