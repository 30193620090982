/* Add to your CSS file, e.g., App.css */
.cookie-policy {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #000;
    color: #fff;
    padding: 10px;
    text-align: center;
    z-index: 1000;
  }
  
  .cookie-policy button {
    margin-left: 10px;
    padding: 5px 10px;
    background-color: #4CAF50;
    color: white;
    border: none;
    cursor: pointer;
  }
  