@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.humanized-inner {
    max-width: 1230px;
    width: 100%;
    padding-top: 100px;
    display: flex;
    padding-bottom: 30px;
    flex-direction: column;
    align-items: center;
    padding-right: 20px;
    padding-left: 20px;
}

.humanized-container {
    display: flex;
    justify-content: center;
}

.humanized-title {
    color: #0A2B72;
    text-align: center;
    font-family: "Poppins", sans-serif;
    font-size: 50px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.humanized-text {
    color: #152a49;
    text-align: center;
    font-family: "Inter", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 35px;
    max-width: 780px;
    margin-top: 30px;
    padding-bottom: 20px;
}

.humanized-img img {
    width: 80px;
    height: 80px;
}

.humanized-detaile-title {
    color: #0A2B72;
    text-align: center;
    font-family: "Inter", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-top: 20px;
    padding-bottom: 15px;
}

.humanized-details-text {
    color: #575E71;
    text-align: center;
    font-family: "Inter", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.humanized-detail-container {
    padding-top: 48px;
    padding-bottom: 48px;
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.humanized-wrapper {
    width: 100%;
}

.humanized-detail {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 40px;
    width: 250px;
}

.humanized-detail-inner {
    display: flex;
    justify-content: center;
    max-width: 240px;
}

.humanized-detail-inner-wrapper {
    display: flex;
    width: 100%;
    justify-content: space-around;
}

@media (max-width: 768px) {
    .humanized-detaile-title {
        max-width: 180px;
    }
    .humanized-detail-inner-wrapper {
        flex-direction: column;
        align-items: center;
    }

    .humanized-detail-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-bottom: 10px;
        padding-top: 60px;;
    }

    .humanized-title {
        font-size: 30px;
        max-width: 384px;
        line-height: 42px;
    }

    .humanized-text {
        font-size: 20px;
        line-height: 30px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .humanized-inner {
        padding-top:115px;
    }
    .humanized-detail {
        padding-bottom:50px ;
    }
    .humanized-details-text {
    font-size: 16px;
    line-height: 24px;
    }

    .humanized-text {
       
        padding-bottom: 0px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
    .humanized-detail-container {
        flex-direction: column;
    }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {}